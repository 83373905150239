import React from 'react';
import PropTypes from 'prop-types';
import { TagChoice } from '@andes/tag';

export const TaggsDesktop = ({ tags, toggleCarousel, carouselActive = '' }) => {
  const namespace = 'ui-ms-tags';
  return (
    <div className={namespace}>
      {tags.map((tag) => (
        <TagChoice
          key={tag.id}
          label={tag.label}
          onClick={() => toggleCarousel(tag)}
          selected={carouselActive === tag.id}
        />
      ))}
    </div>
  );
};

TaggsDesktop.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ),
  toggleCarousel: PropTypes.func.isRequired,
  carouselActive: PropTypes.string,
};
