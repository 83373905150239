module.exports = {
  DEVICE_TYPE_MOBILE: 'mobile',
  IMG_SIZE_SMALL: '160',
  IMG_SIZE_LARGE: '224',
  FAKE_CONTAINER_PRODUCTS: {
    desktop: 4,
    mobile: 4,
    tablet: 4,
  },
  MOBILE_MAX_ITEMS_LENGTH: 4,
};
