import React from 'react';
import PropTypes from 'prop-types';
import { TagChoice } from '@andes/tag';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

export const TaggsMobile = ({
  tags,
  toggleCarousel,
  carouselActive = '',
  title = '',
  viewMoreAction,
}) => {
  const namespace = 'ui-ms-tags';
  const { label = '', target = '' } = viewMoreAction;

  return (
    <div className={namespace}>
      <section className="section-title">
        <h2 className="title-label">{title}</h2>
        <a className="view-more" href={target}>{label}</a>
      </section>
      <CarouselFree srLabel={label}>
        <CarouselFreeSlide>
          {tags.map((tag) => (
            <TagChoice
              key={tag.id}
              label={tag.label}
              onClick={() => toggleCarousel(tag)}
              selected={carouselActive === tag.id}
              size="small"
            />
          ))}
        </CarouselFreeSlide>
      </CarouselFree>
    </div>
  );
};

TaggsMobile.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({}).isRequired,
  ),
  viewMoreAction: PropTypes.shape({
    target: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  toggleCarousel: PropTypes.func.isRequired,
  carouselActive: PropTypes.string,
};
