import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TaggsDesktop, TaggsMobile } from '../../components/taggs';
import { DEVICE_TYPE_MOBILE } from '../../utils/constants/tabbedCarousel';

const TaggedCarouselWrapper = ({
  Carousel,
  propsCarousel,
  shopType = '',
  device = '',
  siteId,
  isFreeCarousel,
}) => {
  const {
    tabs: tags,
    strategy = '',
    title = '',
  } = propsCarousel;
  const [selectedTag, setSelectedTag] = useState(tags[0]);
  const namespace = 'ui-ms-carousel-tags';
  const isMobile = device === DEVICE_TYPE_MOBILE;
  const Taggs = isMobile ? TaggsMobile : TaggsDesktop;
  const titleTag = isMobile ? '' : title;

  const CarouselItems = () => {
    const {
      polycard_context: polycardContextTag,
      polycards: polycardsTag,
      view_more_action: viewMoreAction,
    } = selectedTag;
    return (
      <Carousel
        {...propsCarousel}
        polycards={polycardsTag}
        shopType={shopType}
        device={device}
        viewMoreAction={viewMoreAction}
        polycardContext={polycardContextTag}
        siteId={siteId}
        isFreeCarousel={isFreeCarousel}
        strategy={strategy}
        title={titleTag}
      />
    );
  };

  return (
    <div className={namespace}>
      <Taggs
        title={title}
        tags={tags}
        toggleCarousel={(tag) => setSelectedTag(tag)}
        carouselActive={selectedTag.id}
        viewMoreAction={selectedTag.view_more_action}
      />
      <CarouselItems />
    </div>
  );
};

TaggedCarouselWrapper.propTypes = {
  Carousel: PropTypes.func.isRequired,
  propsCarousel: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        polycards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        polycard_context: PropTypes.shape({}).isRequired,
        view_more_action: PropTypes.shape({}).isRequired,
      }),
    ).isRequired,
    strategy: PropTypes.string,
    viewMoreAction: PropTypes.shape({}),
    style_component: PropTypes.string,
    title: PropTypes.string,
  }),
  shopType: PropTypes.string,
  device: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  isFreeCarousel: PropTypes.bool.isRequired,
};

export default TaggedCarouselWrapper;
