import React from 'react';
import PropTypes from 'prop-types';

import { SnappedCollection } from '@mshops-components-library/snapped-collection';

import EshopsItemCart from './EshopsItemCart';

const EshopsItemCarousel = ({
  items,
  seeElements,
  shopType,
  device,
  viewMoreAction,
  isFreeCarousel,
  fakeCards,
  namespace,
  ...props
}) => (
  <div className={namespace}>
    <SnappedCollection
      {...props}
      seeElements={seeElements}
      eshopType={shopType}
      deviceType={device}
      view_more_action={viewMoreAction}
      isFreeCarousel={isFreeCarousel}
    >
      {
          fakeCards || items?.map((item) => (
            <EshopsItemCart
              item={item}
              deviceType={device}
              seeElements={seeElements}
              eshopType={shopType}
              view_more_action={viewMoreAction}
            />
          ))
        }
    </SnappedCollection>
  </div>
);

EshopsItemCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  seeElements: PropTypes.shape({}),
  shopType: PropTypes.string,
  device: PropTypes.string,
  viewMoreAction: PropTypes.shape({}),
  isFreeCarousel: PropTypes.bool.isRequired,
  fakeCards: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.oneOf([undefined]),
  ]),
  namespace: PropTypes.string,
};

EshopsItemCarousel.defaultProps = {
  items: [],
  seeElements: {},
  shopType: '',
  device: '',
  viewMoreAction: {},
  namespace: 'mshops-recommendations-wrapper',
};

export default EshopsItemCarousel;
